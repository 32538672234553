<template>
  <div>
    <div class="breadcrumbs-button-wrapper" v-if="hasMany">
      <DropdownComponent class="breadcrumbs-dropdown">
        <ButtonComponent
          :icon="['far', 'chevron-down']"
          :label="categori === 'all' ? 'Se alle' : label(categori)"
          @click="hasMany ? () => {} : {}"
        />
        <template v-if="hasMany" #dropdown>
          <ButtonComponent
            v-for="(item, index) in Categories"
            :key="index"
            :label="item === 'all' ? 'Se alle' : label(item)"
            @click="categori = item"
          />
        </template>
      </DropdownComponent>

      <ButtonComponent
        v-for="(item, index) in Categories"
        :key="index"
        :label="item === 'all' ? 'Se alle' : label(item)"
        class="breadcrumbs-button"
        :class="{ 'breadcrumbs-button--active': categori == item }"
        @click="categori = item"
      />
    </div>

    <slot name="top" />

    <div
      v-if="data.articles.length"
      :class="
        layout === 'contactList' ? 'contact-layout' : 'kpb-article-card__wrapper kpb-article-card'
      "
    >
      <component
        v-for="(item, index) in data.articles"
        :key="'article' + index"
        :is="Component(item)"
        :item="item"
        :category="categori"
        v-bind="$attrs"
      />
    </div>

    <slot name="bottom" />
  </div>
</template>

<script>
import { DropdownComponent } from 'vue-elder-dropdown'

import CardComponents from '@/components/CardComponents'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        articles: [],
        content: String,
      }),
    },
    variant: {
      type: String,
      default: 'breadcrumbs',
      validator: val => ['breadcrumbs', 'slider'].includes(val),
    },
    layout: {
      type: String,
      default: 'link',
      validator: val => ['link', 'contactList', 'projects'].includes(val),
    },
    showReadMoreLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      categori: 'all',
    }
  },
  computed: {
    hasMany() {
      // if types contains 'all' and 1 more, hide it
      // means its only 1 categori

      return this.Categories.length > 2
    },

    navItems() {},

    Categories() {
      function Capitalize(value) {
        return value.charAt(0).toUpperCase() + value.substring(1)
      }

      if (!this.data.articles.length) {
        return []
      }

      let types = ['all'].concat(
        this.data.articles
          .map(i => {
            if (
              i?.metadata?.residentialProperties?.propertyType &&
              i?.metadata?.residentialProperties?.propertyType?.length
            ) {
              return i.metadata.residentialProperties.propertyType.map(property => {
                return Capitalize(property)
              })
            } else if (i?.customFields && i?.customFields['site-settings']?.categori) {
              return Capitalize(i.customFields['site-settings'].categori.trim())
            } else if (i?.categori) {
              return Capitalize(i.categori.trim())
            }
          })
          .flat(),
      )

      return [...new Set(types || [])].filter(Boolean)
    },
  },
  methods: {
    Component(item) {
      switch (this.layout) {
        case 'article':
          return CardComponents.Article
        case 'contactList':
          return CardComponents.Contact
        case 'projects':
          if (item?.customFieldsConfig) {
            if (item.customFieldsConfig[0].source === 'project-subpage') {
              return CardComponents.Article
            }
          }
          return CardComponents.Project

        default:
          return CardComponents.Article
      }
    },
    label(item) {
      let locale = this.$i18n.locale

      let localeItem = this.$i18n.messages[locale][item]

      if (localeItem) {
        return localeItem
      }

      let propertyType = 'propertyType:' + this.deCapitalize(item)

      let localePropertyType = this.$i18n.messages[locale][propertyType]

      if (localePropertyType) {
        return this.$tc(propertyType)
      }

      return item
    },
    deCapitalize(value) {
      return value.charAt(0).toLowerCase() + value.substring(1)
    },
  },
  components: {
    DropdownComponent,
    ...CardComponents,
  },
}
</script>

<style lang="scss">
.breadcrumbs-button-wrapper {
  padding-block: 1rem 3rem;
  width: 100%;
  text-align: center;
  .elder-dropdown__content {
    transform: translate(0px, 2rem) !important;
    &-box {
    }
  }
  @include respond-below('phone') {
    .elder-button__label {
      min-width: 180px;
      text-align: center;
    }
  }

  .elder-button__label {
    font-weight: 700;
    text-transform: uppercase;
  }

  .elder-button--default {
  }
  .elder-dropdown__trigger {
    width: 100%;
    .elder-button {
      width: 100%;
    }
  }
}

.breadcrumbs {
  &-button {
    @include respond-below('phone') {
      display: none;
    }
  }
  &-dropdown {
    display: none;
    @include respond-below('phone') {
      display: unset;
    }
  }
}

.breadcrumbs-button {
  /*
  reset border & hover effect
  */
  border: unset;
  border-radius: unset !important;
  &:before {
    display: none;
  }
  margin: 0em 0.5em;
  .elder-button__label {
    padding: 0.25em 0.25em;
  }
  &:not(.breadcrumbs-button--active):hover {
    color: var(--neutral-grey-500);
    border-bottom: 2px solid var(--neutral-grey-500);
  }
  &:not(.breadcrumbs-button--active):active {
    color: var(--neutral-grey-600);
    border-bottom: 2px solid var(--neutral-grey-600);
  }

  &--active {
    border-bottom: 2px solid var(--text-color);
  }
}

.contact-layout {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
  gap: 1rem 3rem !important;
}

.kpb-contact-list {
  color: var(--neutral-grey-900);

  &__name {
    font-size: 1.3333333rem !important;
  }
  &__user-title {
  }
  &__avatar {
    // width: 100%;
  }
  &__phone {
    font-size: 0.9rem;
    &:hover {
      text-decoration: underline;
    }
  }
  &__email {
    font-size: 0.9rem;
    &:hover {
      text-decoration: underline;
    }
  }
  &__contact-info {
    gap: 0.4rem;
    &-icon {
      display: none;
    }
  }
}

.project-card {
  &__text {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    position: relative;
  }
  &__icon {
    width: 1.25em !important;
    --size: 10px;
    width: var(--size);
    height: var(--size) !important;
  }
  &__stats {
    font-weight: 500;

    &-property {
      text-transform: uppercase;
      position: absolute;
      top: 0rem;
      left: 0rem;
      padding: 0.333rem 0.666rem;
      @include body-font(0.889rem);
    }
    &-city {
      @include header-font(12px);
    }
  }
  &__arrow {
    margin-left: 12.5px;
    --size: 15px;
    width: var(--size);
    height: var(--size);
  }
  &__name {
    position: absolute;

    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    @include body-font(27px);
    text-transform: uppercase;
    font-weight: 800;
  }
  &__sub-name {
    @include body-font(22px);
    font-weight: 400;
    position: absolute;
    text-decoration: underline;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__center {
    display: flex;
    align-items: center;
  }
}

.kpb-article-card {
  @keyframes PageTransition {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  --min-height: 400px;

  &__wrapper {
    display: grid;

    grid-template-columns: repeat(auto-fill, minmax(410px, 1fr));

    @include respond-below('phone') {
      grid-template-columns: 1fr !important;
    }

    --card-gap: 0.667rem;

    gap: var(--card-gap);

    @include respond-above('phone') {
      flex-direction: row;
    }
  }

  &__link {
    position: relative;
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    text-decoration: none;
    box-shadow: 0 15px 15px -5px #e6e6e6;

    transition: transform 0.3s, box-shadow 0.3s ease;
    &:hover {
      text-decoration: none;
      transform: scale(1.04);
    }
  }
  &__status {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    word-break: keep-all;

    text-align: center;
    @include header-font(0.778rem);
    font-weight: 500;
    position: absolute;
    top: 20px;
    right: 20px;

    padding: 5px;
    border-radius: 50%;

    height: 100px;
    width: 100px;
    background-color: white;
  }

  &__text {
    @include header-font(0.889rem);
    @include respond-below('phone') {
      @include body-font(1rem);
      font-weight: 500;
      line-height: 1.3;
    }
    padding: 1.5rem;
    padding-bottom: 0;
    word-break: break-word;
    p {
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    h2 {
      font-weight: 400;
      font-size: 1.333rem;
      @include respond-below('phone') {
        @include body-font(1.333rem);
        font-weight: 600;
      }
    }
  }
  &__read-more {
    color: var(--neutral-grey-800);
    @include header-font(0.889rem);

    @include respond-below('phone') {
      @include body-font(1rem);
      font-weight: 600;
      text-decoration: underline;
    }

    display: inline-flex;
    align-items: center;
    gap: 0.8rem;
    padding: 1.5rem;
    padding-top: 0;
    .svg-inline--fa {
      --size: 14px;
      @include respond-below('phone') {
        --size: 16px;
      }
      width: var(--size);
      height: var(--size);
    }
  }

  &__image {
    opacity: 0;
    animation: PageTransition 0.4s forwards;
    min-height: var(--min-height);
    width: 100%;
    height: 100%;
    position: relative;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    // @include respond-below('phone') {
    //   max-height: 400px !important;
    // }
    // @include respond-above('phone') {
    // }
  }
}
</style>
