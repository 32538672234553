<template>
  <footer class="footer" :class="`footer--theme-${theme}`">
    <div class="footer__content">
      <div class="footer__container">
        <div class="footer__logo-wrapper">
          <router-link to="/" @click.native="scrollToTop">
            <img class="footer__logo" :src="logo" width="150" :alt="item.name" />
          </router-link>
        </div>

        <div class="footer__policy footer__clickable">
          <router-link to="/api/legal/privacy/tenant" target="_blank">
            {{ $t('privacyPolicy') }} / {{ $t('cookiePolicy') }}
          </router-link>

          <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>

          <div class="footer__copyright">
            &copy; {{ year }} -

            <span class="footer__bottom">
              {{ $t('pageMadeBy') }}
              <a
                id="https://www.kvass.no"
                :href="`https://www.kvass.no?referrer=${currentUrl}`"
                target="_blank"
                >Kvass</a
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api'
import { GetCustomField } from '@/utils'

import FooterContent from '@kvass/template-footer-content'
import ActionComponent from 'vue-elder-navigation/src/action'
import SoMeFooter from '@/components/SoMeFooter.vue'
import CM from '@/gdpr'

export default {
  props: {
    navigation: Array,
    theme: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'compact'].includes(value)
      },
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),

    customFooterContent() {
      if (!this.item) return
      return GetCustomField('footer-content', this.item.customFields) || []
    },
    someCustomField() {
      if (!this.item) return
      return GetCustomField('social-media', this.item.customFields) || []
    },

    currentUrl() {
      return window.location.href
    },

    logo() {
      //return logo based on primary-color lightness

      if (!this.item.customFields) return

      let backgroundLightness = getComputedStyle(document.documentElement).getPropertyValue(
        '--primary-l',
      )
      if (this.item.customFields['footer-logo']) return this.item.customFields['footer-logo'].url
      if (parseFloat(backgroundLightness) < 70) return API.logoInverted

      return API.logo
    },
  },
  methods: {
    editConsents() {
      CM.show()
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
  },

  metaInfo(){
    return {
      script: [{ src: 'https://unpkg.com/@kvass/widgets@1.0.9/dist/contact.js' }],
    }
  },

  components: {
    ActionComponent,
    FooterContent,
    SoMeFooter,
  },
}
</script>

<style lang="scss">
.footer {
  background-color: white;
  color: var(--footer);

  &__container {
    border-top: 1px solid rgba(219, 219, 219, 255);
    padding-top: 3.5rem;

    padding-inline: var(--section-padding-inline) !important;
    max-width: calc(1280px + calc(var(--section-padding-inline) * 2));
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
    padding-right: 0rem;

    display: grid;
    justify-items: center;

    grid-template-columns: 1fr;
    gap: 1rem;
  }
  &__logo-wrapper {
  }

  &__clickable {
    .elder__navigation-component-icon-right {
      display: none;
    }
    & > button:hover,
    & > a:hover {
      text-decoration: underline;
    }
  }

  &__logo {
    object-fit: contain;
    object-position: left;
    max-width: 200px;
    max-height: calc(var(--nav-height) - 0px) !important;

    width: 100%;

    margin-bottom: 1rem;
  }

  &__content {
    @include respond-above('phone') {
      padding-top: 3.5rem;
    }
    padding-bottom: 2.5rem;
  }

  &__bottom {
    padding-block: 0.5rem;
    padding-top: 1rem;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;

      gap: 1rem;
      flex-wrap: wrap;
    }
    &-text {
      //text-align: justify;
      text-align: center;
    }
  }

  &__policy {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;

    flex-wrap: wrap;
    @include respond-below('phone') {
      flex-direction: column;
    }
    & > * {
      padding: 10px 20px;
    }
  }

  &__menu {
    margin-top: calc(var(--nav-height) / 2);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &-action {
      cursor: pointer;

      background-color: transparent !important;
      color: inherit;
      font-weight: inherit;
    }
  }

  a {
    color: inherit;
    text-decoration: none;

    &[id='https://www.kvass.no'] {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -0.2rem;
        width: 100%;
        height: 2px;
        background-color: var(--primary);
      }
    }
  }

  p {
    margin-top: 0;
  }

  /* Themes */
  &--theme {
    &-compact {
      .footer__content {
        padding-block: 3rem;
      }

      .footer__bottom {
        padding-block: 1rem;
      }
    }
  }
}
</style>
