import { filter } from '@/utils'
import leadContact from './partials/lead-contact'

export default function () {
  return [
    {
      component: 'Section',
      props: {
        width: 'large',
        class: '',
        style: 'padding-block: 3rem;',
      },
      blocks: {
        default: [
          {
            component: 'Embed',
            condition: this.$path('item.customFields.url'),
            props: {
              class: 'header__view',
              style: 'padding-block: 2rem; min-height: 400px;',
            },
            data: {
              src: this.$path('item.customFields.url'),
            },
          },
          {
            component: 'Text',
            condition: this.$path('item.customFields.content'),
            props: {
              class: 'content',
              style: 'padding-block: 2rem;',
            },
            data: {
              content: this.$path('item.customFields.content'),
            },
          },
          {
            component: 'Card',
            condition: this.$path('item.projects'),
            props: {
              showReadMoreLabel: false,
              layout: 'projects',
              style: 'padding-block: 2rem;',
            },
            variant: 'breadcrumbs',
            data: {
              articles: this.$path('item.projects'),
            },
          },
        ].filter(f => filter(f)),
      },
    },
    {
      component: 'Section',
      props: {
        id: 'kontakt'
      },
      blocks: {
        default: {
          component: 'Flex',
          props: {
            class: 'contact-lead',
            alignItems: 'flex-start'
          },
          blocks: {
            default: [
              {
                component: 'ContactList',
                condition: this.$path('item.customFields.lead-contacts.length'),
                data: {
                  contacts: (this.$path('item.customFields.lead-contacts') || [])
                    .map(c => ({
                      avatar: c.image?.url,
                      name: c.name,
                      email: c.email,
                      phone: c.phone,
                      title: c.title
                    })),
                },
                variant: window.innerWidth > 768 ? 'horizontal' : 'vertical',
                props: {
                  aspectRatio: 0.8,
                  gap: '2rem'
                }
              },
              {
                component: 'Lead',

                data: {
                  type: 'page',
                  reference: { onModel: 'Page', ref: this.$path('item.customFields.lead-contacts.length') },
                  fields: ['!upsell', '!title'],
                  defaultData: {},
                  actionLabel: this.$path('item.customFields.lead.action-label') || this.$t('send'),
                },
                blocks: {
                  top: [
                    {
                      component: 'Text',
                      props: {
                        class: 'lead__form-title',
                      },
                      data: {
                        content: `<h2>${this.$path('item.customFields.lead.title') ||
                          this.$tc('getInTouch')}</h2> <span>${this.$path('item.customFields.lead.subtitle',
                        ) || ''}</span>`,
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      }
    }
  ]
}