<template>
  <Loader :value="promises.fetch" theme="default" class="page-page">
    <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BrowserApiMixin from '@/mixins/browser-api'

// pagebuilder
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/page'

// custom components
import PageComponents from '@/components/Page'
import Card from '@/components/Card'

export default {
  mixins: [BrowserApiMixin('page')],
  computed: {
    ...mapState('Page', ['item', 'promises']),

    config() {
      console.log(this)
      return config.call(this)
    },
    customComponents() {
      return { ...PageComponents, Card }
    },
  },
  methods: {
    ...mapActions('Page', ['fetch']),

    onScroll() {
      requestAnimationFrame(() => {
        const scrollTop = window.scrollY / 4

        document.body.style.setProperty('--parallax-top', `${scrollTop}px`)
      })
    },
  },
  created() {
    this.fetch()
  },
  mounted() {
    document.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll)
  },
  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.page-page {
  .content {
    max-width: 50rem;
    margin: 0 auto;
    font-size: 1rem;
    
    h2 {
      font-weight: 400;
      font-size: 2rem;
    }
  }
}
</style>
