<template>
  <div v-if="filter">
    <PagebuilderContactList
      :data="{
        contacts: [item],
      }"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import PagebuilderContactList from '@kvass/pagebuilder/src/components/ContactList.vue'
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    category: {
      type: String,
      default: '',
    },
  },
  computed: {
    filter() {
      if (this.category === 'all') return true
      if (!this.item.categori) return false

      return this.item.categori.toUpperCase().trim() === this.category.toUpperCase().trim()
    },
  },
  components: {
    PagebuilderContactList,
  },
}
</script>

<style lang="scss"></style>
