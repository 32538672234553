<template>
  <div
    v-if="
      item.metadata.residentialProperties.propertyType.includes(deCapitalize(category)) ||
        category == 'all'
    "
    class="kpb-article-card"
  >
    <div class="kpb-article-card__wrapper">
      <a :href="item.url" class="kpb-article-card__link">
        <div
          :style="{ backgroundImage: 'url(' + getImage(item.media.cover) + ')' }"
          class="kpb-article-card__image"
        >
          <div class="project-card__text">
            <span class="project-card__stats project-card__stats-property">{{
              housingTypes(item.metadata.residentialProperties)
            }}</span>

            <span class="project-card__name"> {{ item.name }} </span>
            <span class="project-card__sub-name"> Se eiendom </span>
          </div>
        </div>

        <div></div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    category: {
      type: String,
      default: '',
    },
  },

  methods: {
    housingTypes(types) {
      return types.propertyType
        .map(type => {
          let propertyType = 'propertyType:' + type

          /*
          liten potensiel bug,
          hvis de har feks bolig til 200000kvm og kontor til 100000kvm
          så vises bolig til 200000kvm på kontor.
          */
          if (type === 'office') {
        
       
            if (Math.max(types.useableArea))  return this.$tc(propertyType) + ' - ' + Math.max(types.useableArea) + ' kvm'
          
          }

          return this.$tc(propertyType)
        })
        .join(' | ')
    },
    deCapitalize(value) {
      return value.charAt(0).toLowerCase() + value.substring(1)
    },
    getImage(value) {
      if (!value || !value.length) return
      let result = value.find(i => {
        if (i.type) return i.type.startsWith('image')
        return i
      })
      if (!result) return
      return result.url
    },
    getStatus(item) {
      let total = item.stats.total
      let stats = item.stats

      if (!total) return 'Under utvikling'

      if (item.metadata.residentialProperties.saleType.includes('rent')) {
        if (stats.rent) return 'Ledig for leie'
        if (stats.upcoming) return 'Kommer for leie'
        if (stats.rented) return 'Utleid'
        return 'Under utvikling'
      } else {
        if (stats.sale) return 'Til salgs'
        if (stats.upcoming) return 'Kommer for salg'
        if (stats.sold) return 'Solgt'
        return 'Under utvikling'
      }
    },
  },
}
</script>

<style lang="scss"></style>
