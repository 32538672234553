import Vue from 'vue'
import VueI18n from 'vue-i18n'
import moment from 'moment'

const initialLanguage = 'nb'
const messages = require(`../i18n/${initialLanguage}.json`)
const loadedLanguages = [initialLanguage]

const getLanguageMeta = () => {
  try {
    return document.head.querySelector('meta[name="language"][source="kvass"]').content
  } catch (err) {
    return initialLanguage
  }
}

const defaultLanguage = getLanguageMeta()

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: initialLanguage,
  fallbackLocale: initialLanguage,
  messages: {
    nb: messages,
  },
})

moment.locale(initialLanguage)
loadLanguageAsync(defaultLanguage)

function setI18nLanguage(lang) {
  i18n.locale = lang
  moment.locale(lang)
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export async function loadLanguageAsync(lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(/* webpackChunkName: "lang-[request]" */ `../i18n/${lang}.json`).then(msgs => {
        i18n.setLocaleMessage(lang, msgs)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      })
    }
    return setI18nLanguage(lang)
  }
  return lang
}

export function mapi18n(key, value) {
  function resolveEntry(entry) {
    let key, args

    switch (entry.constructor) {
      case String:
        key = args = entry
        break
      case Array:
        ;[key, args] = entry
        break
      case Object:
        ;[key, args] = Object.entries(entry)[0]
    }

    switch (args.constructor) {
      case Function:
        return [key, args.call(this)]
      case String:
        return [key, i18n.tc(args)]
      case Array:
        return [key, i18n.tc(...args)]
      default:
        return [key, i18n.tc(key, args)]
    }
  }

  return {
    [key]() {
      let base = value instanceof Array ? value : Object.entries(value)
      return Object.fromEntries(base.map(resolveEntry.bind(this)))
    },
  }
}