<template>
  <div v-if="filter" class="kpb-article-card__wrapper">
    <router-link :to="`/${item.parentSlug}/${item.slug}`" class="kpb-article-card__link">
      <div
        :style="{ backgroundImage: 'url(' + getImage(item.media.cover) + ')' }"
        class="kpb-article-card__image"
      >
        <span v-if="item.customFields['site-settings'].status" class="kpb-article-card__status">{{
          getStatus(item.customFields['site-settings'].status)
        }}</span>
      </div>

      <div v-html="item.content" class="kpb-article-card__text"></div>
      <div class="kpb-article-card__read-more">
        <span class="kpb-article-card__read-more-content">Les mer</span>
        <FontAwesomeIcon :icon="['far', 'arrow-right']" class="kpb-article-card__read-more-icon" />
      </div>
    </router-link>
  </div>
</template>

<script>
const categoryMap = {
  rentUpcoming: 'Kommer for leie',
  rent: 'Ledig for leie',
  rented: 'Utleid',
  sale: 'Til salgs',
  sold: 'Solgt',
  saleUpcoming: 'Kommer for salg',
  development: 'Under utvikling',
}

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    category: {
      type: String,
      default: '',
    },
  },
  computed: {
    filter() {
      if (this.category === 'all') return true
      if (!this.item.customFields['site-settings']) return false

      return (
        this.item.customFields['site-settings'].categori.toUpperCase().trim() ===
        this.category.toUpperCase().trim()
      )
    },
  },
  methods: {
    getImage(value) {
      if (!value || !value.length) return
      let result = value.find(i => {
        if (i.type) return i.type.startsWith('image')
        return i
      })
      if (!result) return
      return result.url
    },
    getStatus(item) {
      if (categoryMap[item]) return categoryMap[item]

      return categoryMap['development']
    },
  },
}
</script>

<style lang="scss"></style>
