<template>
  <div
    id="app"
    :class="['route--' + view.toLowerCase(), isOnTop ? 'page--is-top' : 'page--is-scrolled']"
  >
    <div class="demo-indicator" v-if="isDemo">
      <FontAwesomeIcon icon="exclamation-circle" />
      <span>{{ $t('thisIsADemo') }}</span>
    </div>
    <!-- <div class="transition">
      <img class="transition__img" :src="logo.src || logo" />
    </div> -->
    <div
      v-if="$route.meta.navigation !== false"
      class="nav"
      :class="[
        `nav--${view.toLowerCase()}`,
        { [`nav--${view.toLowerCase()}-expanded`]: isExpanded },
        { 'nav-is-hidden': !showNavbar && !isExpanded },
      ]"
    >
      <div class="container">
        <NavigationComponent
          :logo="logo"
          :items="items"
          :title="projectName"
          padding="20px 0"
          :action="mainAction || redirect(['Page', 'Residential'], { scrollTo: 0 })"
          :isOpen.sync="isExpanded"
        />
      </div>
    </div>
    <transition mode="out-in" name="route">
      <router-view />
    </transition>
    <FooterComponent
      v-if="$route.meta.footer !== false"
      :navigation="items.filter(i => i.class !== 'social-media')"
    />
    <AssetComponent />
  </div>
</template>

<script>
import { NavigationComponent } from 'vue-elder-navigation'
import { mapState, mapActions } from 'vuex'
import AssetComponent, { EventBus as AssetBus } from '@kvass/asset-manager'
import FooterComponent from '@/components/Footer'
import API from './api'
import BrowserApiMixin from '@/mixins/browser-api'

import variables from '@/styles/base/_variables.scss'

export default {
  mixins: [BrowserApiMixin('app')],
  data() {
    return {
      isOnTop: true,
      customItems: [],
      isExpanded: false,
      showNavbar: true,
      lastScrollPosition: 0,
      scrollOffset: parseFloat(variables.navHeight),
    }
  },
  computed: {
    ...mapState('Root', ['item', 'promises']),
    logos() {
      return {
        light: API.logoInverted,
        dark: API.logo,
      }
    },

    view() {
      return this.$path('name', this.$route) || ''
    },
    logo() {
      return this.logos.dark
    },
    isDemo() {
      return this.item.demo || false
    },
    labels() {
      return KvassConfig.get('customLabels') || {}
    },
    projectName() {
      return this.item.name || ''
    },
    seo() {
      return this.$path('customFields.seo', this.item) || {}
    },
    hasFlatfinder() {
      return this.$path('flatfinders.length', this.item)
    },
    hasStatsTotal() {
      return this.$path('stats.total', this.item)
    },
    hasResidentials() {
      return this.$path('$store.state.Residentials.item.docs.length')
    },
    hasAttachments() {
      return this.$path('media.attachments.length', this.item)
    },
    brochure() {
      return this.$path('media.brochure', this.item) || []
    },
    brochureStrategy() {
      return KvassConfig.get('siteSettings.brochureDownloadStrategy') || 'direct'
    },
    posts() {
      return this.$path('posts', this.item) || []
    },

    gallery() {
      return this.$path('media.gallery', this.item) || []
    },

    items() {
      return [...this.defaultItems, ...this.customItems].sort((a, b) => a.order - b.order)
    },
    addons() {
      return this.$path('item.metadata.addons') || []
    },
    redirectUrl() {
      return (
        this.$path('item.publishing.redirectUrl') &&
        `https://${this.$path('item.publishing.redirectUrl')}`
      )
    },
    mainAction() {
      if (this.addons.includes('project:flatfinder')) return this.redirectUrl

      return this.redirect('Page', { scrollTo: 0 })
    },

    defaultItems() {
      return [
        // {
        //   icon: ['far', 'arrow-right'],
        //   label: this.$t('home'),
        //   action: this.mainAction,
        //   condition: this.mainAction,
        //   order: 0,
        //   class: 'navigation-page',
        // },
        // ...this.posts.map(i => {
        //   if (this.$path('customFieldsConfig.0.source', i) === 'contacts') {
        //     return {
        //       label: i.title,
        //       class: 'elder__navigation-component--primary',
        //       exact: true,
        //       action: this.redirect(['Post'], { params: { slug: i.slug }, forceRedirect: true }),
        //       order: 100,
        //     }
        //   }
        //   if (!this.$path('customFields.site-settings.show-in-navigation', i)) return {}
        //   return {
        //     icon: ['far', 'arrow-right'],
        //     label: i.title,
        //     exact: true,
        //     action: this.redirect(['Post'], { params: { slug: i.slug }, forceRedirect: true }),
        //     order: this.$path('customFields.site-settings.navigation-order', i) * 10 - 5,
        //     class: 'navigation-page',
        //   }
        // }),
        // {
        //   icon: ['fab', 'linkedin'],
        //   class: 'social-media',
        //   action: this.$path('item.customFields.social-media.linkedin_url'),
        //   order: 101,
        //   target: '_blank',
        // },
        // {
        //   icon: ['fab', 'facebook'],
        //   class: 'social-media',
        //   action: this.$path('item.customFields.social-media.facebook_url'),
        //   order: 102,
        //   target: '_blank',
        // },
        // {
        //   icon: ['fab', 'instagram'],
        //   class: 'social-media',
        //   action: this.$path('item.customFields.social-media.instagram_url'),
        //   order: 103,
        //   target: '_blank',
        // },
        // {
        //   icon: ['fab', 'twitter'],
        //   class: 'social-media',
        //   action: this.$path('item.customFields.social-media.twitter_url'),
        //   order: 104,
        //   target: '_blank',
        // },
      ]
        .filter(x => !('condition' in x) || x.condition)
        .filter(x => x.action || (x.items && x.items.length))
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
    redirect(name, options = { forceRedirect: false }) {
      return async function() {
        name = name instanceof Array ? name : [name]

        let { action, scrollTo, hash, params, forceRedirect } = options

        if (!name.includes(this.$route.name) || params?.slug != this.$route.query?.slug)
          await this.$router.push({ name: name[0], hash, params })
        else if (hash) {
          let target = document.querySelector(
            '.scroll-anchor--type-anchor.scroll-anchor--value-' + hash.substring(1),
          )
          if (!target) return
          target.scrollIntoView({ behavior: 'smooth' })
        }
        if (action) return this.action()
        if (scrollTo !== undefined) window.scrollTo(0, scrollTo)
      }
    },
    onScroll() {
      if (window.pageYOffset < 0) {
        return
      }

      /*
       ##Notes
       scrollOffsett = navbar height
       
       scrollOffset / 4 to counter parallax effect
      
       parallax effect - calc
       const scrollTop = window.scrollY / 4

       */

      if (
        Math.abs(window.pageYOffset - this.lastScrollPosition) <
        this.scrollOffset + this.scrollOffset / 4
      ) {
        return
      }

      this.showNavbar = window.pageYOffset < this.lastScrollPosition
      this.lastScrollPosition = window.pageYOffset
    },
  },
  created() {
    const debounce = fn => {
      let frame
      return (...params) => {
        if (frame) cancelAnimationFrame(frame)
        frame = requestAnimationFrame(() => fn(...params))
      }
    }

    const storeScroll = () => {
      this.isOnTop = window.scrollY <= 5
    }

    document.addEventListener('scroll', debounce(storeScroll), { passive: true })
    storeScroll()

    this.promise = this.fetch()
  },
  mounted() {
    this.lastScrollPosition = window.pageYOffset
    if (window.innerWidth < parseFloat(variables.phone)) {
      window.addEventListener('scroll', this.onScroll)
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  metaInfo() {
    return {
      titleTemplate: this.projectName
        ? `%s | ${this.$path('seo.title') || this.projectName}`
        : `${this.$t('loading', { resource: null })}...`,
      link: [
        { rel: 'preload', href: this.logos.dark, as: 'image' },
        { rel: 'preload', href: this.logos.light, as: 'image' },
        { rel: 'icon', href: API.favicon, type: 'image/png' },
      ],
    }
  },
  components: {
    NavigationComponent,
    FooterComponent,
    AssetComponent,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.social-media {
  color: var(--primary) !important;
  &:hover {
    color: var(--neutral-grey-500) !important;
  }
  &:active {
    color: var(--neutral-grey-600) !important;
  }
  padding: 12px 0px 12px 0px !important;
  margin: 0 !important;
  .elder__navigation-component-icon-right {
    margin: 0;
  }
  .svg-inline--fa {
    width: 22px;
    height: 23px;
  }
}
.nav-is-hidden {
  transform: translateY(-100%);
}

.navigation-page {
  .elder__navigation-component-icon-right {
    display: none;
  }
}

.nav {
  transition: transform 300ms linear;
  height: var(--nav-height);
  border-bottom: 1px solid #aeb2b6;

  .container {
    padding-inline: var(--section-padding-inline) !important;
    max-width: calc(1280px + calc(var(--section-padding-inline) * 2));
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  position: sticky;
  top: 0;
  width: 100%;
  z-index: get-layer('nav');
  background: var(--secondary);

  .elder__navigation-bars {
    color: var(--primary);
    border-radius: 100%;
    padding: 14px;
    cursor: pointer;
  }

  .elder__navigation {
    // max-width: 1700px;
    // margin: 0 auto;
    padding-block: 0.5rem !important;

    &-actions > * + * {
      margin-left: 1rem;
    }

    &-component {
      color: var(--neutral-grey-900);
      font-weight: 500;
      cursor: pointer;
      border-radius: 3px;
      font-size: 1rem;
      font-family: var(--secondary-font);
      padding: 12px 16px 12px 16px;
      // margin-bottom: 0.5rem;
      position: relative;

      &:not(.elder__navigation-component--primary):not(.social-media):hover:after {
        content: '';

        bottom: 0px !important;
        left: 0px !important;

        width: 100% !important;
        height: 3px !important;
        background: var(--primary);
      }

      &--primary {
        margin-left: 1rem;
        background-color: var(--primary);
        color: var(--secondary);
        font-weight: bold;
        padding: 12px 24px 12px 24px;
        &:hover {
          // background-color: hsl(var(--primary-h), var(--primary-s), 20%);
        }
      }
    }

    &-logo {
      margin-left: auto;
    }

    &-logo-image {
      max-height: calc(var(--nav-height) - 40px) !important;
      cursor: pointer;
    }
  }

  &--postsubpage-expanded,
  &--project-expanded,
  &--residential-expanded,
  &--contact-expanded,
  &--areals-expanded,
  &--post-expanded {
    .elder__navigation-component-icon-right {
      display: unset !important;
      --size: 14px;
      width: var(--size);
      height: var(--size);
    }

    .container {
      padding-inline: unset !important;
    }
    .elder__navigation-logo {
      padding-inline-start: 2rem !important;
    }
    .elder__navigation-bars {
      padding-inline-end: 2rem !important;
    }

    padding-bottom: 1rem;

    background-color: hsla(var(--secondary-h), var(--secondary-s), var(--secondary-l), 1);

    backdrop-filter: blur(5px);

    height: 100vh;

    display: inline-flex;

    & > .container {
      display: flex;
    }

    .elder__navigation {
    }

    .elder__navigation-actions > * + * {
      margin-left: 0rem;
    }

    .elder__navigation-actions {
      margin-left: 0rem !important;
      display: inline-flex !important;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0rem;
      justify-content: center;
      align-items: center;
      font-size: 1.25rem;

      .elder__navigation-node {
        padding: 1rem 4rem;
        width: 100%;
      }
      .elder__navigation-node:has(.elder__navigation-component--primary) {
        padding: 2rem 2.5rem;
      }
      .elder__navigation-node:has(.navigation-page) {
        background-color: var(--grey);
      }
      .elder__navigation-node:has(.social-media) {
        order: 2;
        padding: 1rem 2rem;
        width: 1px !important;
        .svg-inline--fa {
          width: 21px;
          height: 23px;
        }
      }

      .navigation-page {
        font-size: 1.25rem;
        width: 100%;
      }

      .elder__navigation-component {
        &:not(.elder__navigation-component--primary) {
          color: var(--black);
        }
        &--primary {
          margin-left: 0rem;
          justify-content: center;
        }

        &:not(.elder__navigation-component--primary):hover:after {
          display: none;
        }
        &:not(.elder__navigation-component--primary):hover {
          background-color: var(--nav-hover-bars);
        }
      }
    }
  }
}

.demo-indicator {
  background-color: var(--warning);
  color: white;

  padding: 0.5em 0.75em;

  font-size: 0.8em;
  letter-spacing: 0.5px;
  line-height: 1;

  svg {
    margin-right: 5px;
  }
}

.route-enter,
.route-leave-to {
  opacity: 0;
  transform: scale(0.98);
}

.route-enter-to,
.route-leave {
  opacity: 1;
  transform: scale(1);
}

.route-enter-active,
.route-leave-active {
  transform-origin: top;
  transition: 500ms ease;
}
</style>
